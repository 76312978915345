<template>
  <div>
    <el-upload
      ref="uploadForm"
      drag
      multiple
      action=""
      show-file-list
      :file-list="fileList"
      :limit="limit"
      :http-request="handleUploadFile"
      :on-exceed="handleExceed"
      :before-upload="beforeUpload"
      :on-success="handleSuccess"
      :on-remove="handleRemove"
    >
      <i class="el-icon-upload" />
      <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
      <div slot="tip" class="el-upload__tip">支持单个或批量上传。所有格式文件均可上传。单个文件最大不超过{{ size }}M</div>
      <div slot="file" slot-scope="{file}">
        <el-button type="text" :loading="uploadLoading" @click="downloadReplenish(file)">
          <el-popover
            placement="top-start"
            trigger="hover"
          >
            <div>{{ file.fileName }}</div>
            <div slot="reference" style="width:300px;text-overflow:ellipsis;white-space:nowrap;overflow:hidden;text-align:start">{{ file.fileName }}</div>
          </el-popover>
        </el-button>
        <el-button style="color: red" type="text" :loading="uploadLoading" @click="handleRemove(file)">删除</el-button>
      </div>
    </el-upload>
  </div>
</template>
<script>
import { uploadByPreUrlApi } from '@/api/scm-api'
import axios from 'axios'
export default {
  props: {
    fileList: {
      type: Array,
      default: () => []
    },
    limit: {
      type: Number,
      default: 30
    },
    size: {
      type: Number,
      default: 300
    }
  },
  data() {
    return {
      uploadLoading: false
    }
  },
  mounted() {
  },
  methods: {
    async downloadReplenish(file) {
      try {
        this.uploadLoading = true
        const { fileUrl } = file
        window.open(fileUrl, '_blank')
        this.uploadLoading = false
      } finally {
        this.uploadLoading = false
      }
    },
    beforeUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < this.size
      if (!isLt2M) {
        this.$message.error(`文件最大不超过${this.size}MB`)
        return false
      } else {
        return true
      }
    },
    handleExceed(files, fileList) {
      this.$message.error(`当前限制选择 ${this.limit} 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`)
    },
    async handleUploadFile(options) {
      const { name: fileName, size: kbSize } = options.file
      const params = {
        fileName: fileName,
        kbSize: Math.floor(kbSize / 1024),
        fileType: fileName.substring(fileName.lastIndexOf('.') + 1),
        configCode: 'vendorOfferAttachment'
      }
      const { datas } = await uploadByPreUrlApi(params)
      const {
        originName: name,
        objectName: key,
        policy,
        accessid: OSSAccessKeyId,
        callback,
        signature,
        host: url
      } = datas
      const form = Object.assign(
        {},
        { name, key, policy, OSSAccessKeyId, callback, signature },
        { file: options?.file }
      )
      const formData = new FormData()
      Object.keys(form).forEach((key) => formData.set(`${key}`, form[key]))
      const { data: { datas: response }} = await axios({
        method: 'post',
        url,
        data: formData
      })
      console.log('response', response)
      response && options.onSuccess(response)
      this.$emit('handleFileSuccess', response)
    },
    handleSuccess(response) {
      if (response) {
        console.log('handleSuccess: ', response, this.fileList)
      }
    },
    handleRemove(val) {
      if (val.status === 'ready') return
      this.$emit('handleDelete', val)
    }
  }

}
</script>
