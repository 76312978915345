<template>
  <div>
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="80%"
      center
      :close-on-click-modal="false"
      :before-close="closed"
    >
      <el-form ref="ruleForm" :model="ruleForm" label-position="right" label-width="100px" :rules="rules">
        <el-form-item label="主题" prop="emailSubject">
          <el-input v-model="ruleForm.emailSubject" style="width: 90%" :maxlength="500" show-word-limit />
        </el-form-item>
        <el-form-item label="收件人" prop="receiverEmail">
          <el-input v-model="ruleForm.receiverEmail" style="width: 90%" :maxlength="1000" show-word-limit />
        </el-form-item>
        <el-form-item label="抄送" prop="ccEmail">
          <el-input v-model="ruleForm.ccEmail" style="width: 90%" :maxlength="1000" show-word-limit />
        </el-form-item>
        <el-form-item label="密送" prop="bccEmail">
          <el-input v-model="ruleForm.bccEmail" style="width: 90%" :maxlength="1000" show-word-limit />
        </el-form-item>
        <el-form-item label="内容" prop="emailContent">
          <wangEdit
            v-model="ruleForm.emailContent"
            :type="type"
            :version-html="versionHtml"
            :height="300"
            style="width: 90%"
            @getHtml="getHtml"
          />
        </el-form-item>
        <el-form-item label="附件" prop="attachments">
          <DragUpload v-model="ruleForm.attachments" :file-list="ruleForm.attachments" :limit="30" :size="300" @handleFileSuccess="handleFileSuccess" @handleDelete="handleDelete" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closed">取 消</el-button>
        <el-button type="primary" :loading="loading" @click="onSubmit">确认发送</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import wangEdit from '@/components/ImagesEditor'
import DragUpload from '@/components/DragUpload'
import { deepClone } from '@/utils'
export default ({
  components: { wangEdit, DragUpload },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: '邮件'
    },
    detailDatas: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    var checkEmail = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('page.required')))
      } else {
        const arr = value.split('；')
        const err = /^[^@\s]+@[^@\s]+\.[^@\s]+$/
        const errMap = arr.every(i => i.match(err))
        if (!errMap) {
          callback('邮箱格式错误')
        } else {
          callback()
        }
      }
    }
    var checkHtml = (rule, value, callback) => {
      const val = value.replace(/<[^<p>]+>/g, '') // 将所有<p>标签 replace ''
        .replace(/<[</p>$]+>/g, '') // 将所有</p>标签 replace ''
        .replace(/&nbsp;/gi, '') // 将所有 空格 replace ''
        .replace(/<[^<br/>]+>/g, '') // 将所有 换行符 replace ''

      if (val === '') {
        callback(new Error(this.$t('page.required')))
      } else {
        var regu = '^[ ]+$'
        var re = new RegExp(regu)
        const err = re.test(val)
        if (err) {
          callback(new Error(this.$t('page.required')))
        } else {
          callback()
        }
      }
    }
    return {
      ruleForm: {
        emailSubject: '',
        receiverEmail: '',
        emailContent: '',
        ccEmail: '',
        bccEmail: '',
        attachments: []
      },
      rules: {
        emailSubject: { required: true, message: this.$t('page.required'), trigger: 'blur' },
        receiverEmail: [{ required: true, message: this.$t('page.required'), trigger: 'blur' }, { validator: checkEmail, trigger: 'blur' }],
        ccEmail: [{ required: true, message: this.$t('page.required'), trigger: 'blur' }, { validator: checkEmail, trigger: 'blur' }],
        emailContent: [{ required: true, message: this.$t('page.required'), trigger: 'blur' }, { validator: checkHtml, trigger: 'blur' }]
      },
      type: '',
      versionHtml: '',
      loading: false
    }
  },
  computed: {
    dialogVisible: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  watch: {
    detailDatas(val) {
      if (val) {
        const { attachments } = this.detailDatas
        this.ruleForm = deepClone(Object.assign(this.detailDatas, { attachments: attachments?.map(i => Object.assign(i, { uid: i.uid || i.id || i.fileUrl })) || [] }))
        this.versionHtml = this.detailDatas.emailContent
      }
    }
  },
  methods: {
    getHtml(val) {
      if (!val) return
      this.ruleForm.emailContent = val
    },
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          try {
            this.loading = true
            this.$emit('handleSubmit', this.ruleForm)
          } finally {
            this.loading = false
          }
        }
      })
    },
    closed() {
      this.dialogVisible = false
      this.$refs.ruleForm.resetFields()
    },
    handleFileSuccess(val) {
      const { downloadUrl, originFileName } = val
      Object.assign(val, { fileUrl: downloadUrl, fileName: originFileName })
      this.ruleForm.attachments.push(val)
    },
    handleDelete(val) {
      const index = this.ruleForm.attachments.findIndex((item) => (item.uid === val.uid))
      index !== -1 && this.ruleForm.attachments.splice(index, 1)
    }
  }
})
</script>
<style scoped>

</style>
